<template>
	<div class="formulaire__juridique">
		<svg class="logo" fill="#3d2f59" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 48 60" enable-background="new 0 0 48 48" xml:space="preserve"><g><path d="M43.4,0C43.3,0,43.3,0,43.4,0c-0.1,0-0.1,0-0.1,0H11.9C9.3,0,7.3,2.1,7.3,4.6v37.7H1c-0.6,0-1,0.4-1,1   C0,45.9,2.1,48,4.6,48h31.5c0,0,0,0,0,0c2.5-0.1,4.5-2.1,4.5-4.6V4.6c0-1.4,1.2-2.6,2.6-2.6C44.8,2,46,3.2,46,4.6s-1.2,2.6-2.6,2.6   c-0.6,0-1,0.4-1,1s0.4,1,1,1c2.6,0,4.6-2.1,4.6-4.6C48,2.1,45.9,0,43.4,0z M4.6,46c-1.1,0-2-0.7-2.4-1.6h29.4   c0.1,0.6,0.4,1.1,0.7,1.6H4.6z M38.7,4.6v38.7c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6c0-0.6-0.4-1-1-1H9.3V4.6   c0-1.4,1.2-2.6,2.6-2.6h27.7C39.1,2.8,38.7,3.7,38.7,4.6z"/><path d="M13.3,11.4h9.5c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-9.5c-0.6,0-1,0.4-1,1C12.3,11,12.7,11.4,13.3,11.4z"/><path d="M34.7,16.5H13.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h21.5c0.6,0,1-0.4,1-1C35.7,16.9,35.3,16.5,34.7,16.5z"/><path d="M34.7,23.5H13.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h21.5c0.6,0,1-0.4,1-1C35.7,24,35.3,23.5,34.7,23.5z"/><path d="M34.7,30.6H13.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h21.5c0.6,0,1-0.4,1-1S35.3,30.6,34.7,30.6z"/></g></svg>
		<h3 class="mt-10">Le service juridique</h3>
		<v-form 
			v-model="opt_form.valide"
			mode="aggressive"
			ref="form"
		>
			<InputSearchCab 
				@change="cab_change"
				class="mt-8"
				require
			>
			</InputSearchCab>
			<v-text-field 
				outlined
				v-model="cab"
				:rules="opt_form.rules.cab"
				class="mt-4"
				v-show="false"
				id="hidden-searchcab"
			></v-text-field>
			<v-text-field 
				outlined
				v-model="objet"
				label="Objet de la demande"
				:rules="opt_form.rules.objet"
				class="mt-4"
			></v-text-field>
			<v-textarea
				outlined
				name="input-7-4"
				label="Rédigez votre demande"
				v-model="content"
				:rules="opt_form.rules.content"
				class="mt-4"
			></v-textarea>
			<InputFileUpload
				colorBack="#3d2f59"
				class="mt-4 mb-10"
				@change="files_changes"
			></InputFileUpload>
			<v-btn 
				class="mt-0" 
				depressed 
				color="#3d2f59" 
				:dark="opt_form.valide"
				:disabled="!opt_form.valide"
				@click="send"
			>
				Envoyer
			</v-btn>
			<v-btn 
				class="ml-4" 
				depressed 
				@click="$router.go(-1);"
			>
				Retour
			</v-btn>		
		</v-form>
	</div>
</template>

<script>
	import InputSearchCab from '../InputCabSearch.vue'
	import InputFileUpload from '../InputFileUpload.vue'
	import { mapActions, mapMutations } from 'vuex';

	export default {
		name: 'Juridique',
		components : {
			InputSearchCab,
			InputFileUpload
		},
		data: () => ({
			cab: "",
			objet: "",
			content: "",
			files: [],
			opt_form: {
				cab_error : false,
				lazy: false,
				valide: false,
				rules : {
					content : [
						v => v != "" ? true : 'Le message est requis',
						v => v.length > 5 ? true : "Le message est trop court"
					],
					objet : [
						v => v != "" ? true : "L'objet est requis",
						v => v.length > 2 ? true : "L'objet' est trop court"
					],
					cab : [
						v => v != "" ? true : "Le cab est requis",
					]
				}
			},
		}),
		methods: {
			...mapActions('formulaires', [
				"sendJuridique",
			]),
			...mapMutations('formulaires', [
				"setNotif",
				"setNotifMsg"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			cab_change: function(e) {
				this.cab = e;
			},
			files_changes: function(e) {
				this.files = e;
			},
			send: function() {
				var _this = this;

				this.sendJuridique({
					cab: this.cab,
					objet: this.objet,
					content: this.content,
					files: this.files.map(x => {
						return x.file_id
					})
				})
				.then(() => {
					_this.sendSuccessNotif("Opération effectuée")
					_this.$router.push('/');
				})
			}
		},
	};
</script>

<style lang="scss">
	.formulaire__juridique {
		width: 40%;
		border: 4px solid #f1f1f1;
		border-radius: 16px;
		margin-top: 30px;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.logo
		{
			width: 50px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			top: -25px;
			left:0;
			right: 0;
			z-index: 1000;
		}
	}

	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire__juridique
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire__juridique
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire__juridique
		{
			width: 90%;
		}
	}
</style>